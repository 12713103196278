@import '_variables';

.body-page-container {
  text-align: center;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 15px;
  padding: 15px;

  .main-card {
    box-sizing: border-box;
    margin: 30px 0px;
    .card {
      display: inline-block;
      padding: 100px 0;
      width: 100%;
    }
    span {
      color: rgb(150,150,150);
      font-size: 12px;
      display: block;
    }
    input {
      border-color: black;
      background-color: white;
      width: 100%;
      max-width: 230px;
    }
  }
  .stats-card {
    h3 {
      text-align: left;
      margin-top: 0;
    }
    span {
      font-size: 32px;
    }
  }

  .bodyweight-table-container {
    display: inline-block;
    max-width: 500px;
    position: relative;

    table {
      width: 100%;
      th {
        color: grey;
      }
      tr {
        cursor: pointer;
        &.selected {
          background-color: rgba(white,0.3);
        }
        td {
          padding: 10px 20px;
          color: white;
          transition: 0.5s ease;
        }
      }
    }
  }

  .bodyweight-plot-container {
    padding: 20px;
    position: relative;
    svg {
      text {
        fill: white;
      }
      path {
        stroke: $light-colour-2;
      }
      .std path {
        stroke: none;
        fill: rgba($light-colour-2, 0.2);
      }
      .x-axis, .y-axis {
        path {
          stroke: white;
        }
      }
    }
  }

  @media #{$computer-and-tablet} {
    .main-card {
      margin: 100px 0;
      .card {
        padding: 100px;
        width: unset;
      }
    }
  }
}

