@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #000000;
  text-align: center;
  position: relative;
  min-height: 100vh;
  background-image: -webkit-gradient(linear, left top, right bottom, from(#D88181), to(#C988DA));
  background-image: linear-gradient(to bottom right, #D88181, #C988DA); }

main {
  min-height: 100vh;
  width: 100%;
  max-width: 1000px;
  text-align: center;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 15px;
  align-content: start;
  padding: 15px; }
  main .card {
    background-color: #3F2942;
    color: white;
    border-radius: 5px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.4);
    overflow: hidden;
    padding: 15px; }
  main .main-card {
    box-sizing: border-box;
    margin: 30px 0px; }
    main .main-card .card {
      display: inline-block;
      padding: 100px 0;
      width: 100%; }
    main .main-card span {
      color: #969696;
      font-size: 12px;
      display: block; }

div.notification-container {
  position: fixed;
  top: 0;
  padding: 20px;
  z-index: 10; }
  div.notification-container .notification {
    text-align: left;
    background-color: white;
    border: 1px solid black;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: 10px; }

a {
  color: #000000;
  outline: none;
  text-decoration: none; }

input[type=text], input[type=password], input[type=search], input[type=email], input[type=range], input[type=date], input[type=time], input[type=number], input[type=checkbox], input[type=radio] {
  vertical-align: middle;
  color: #000000;
  background-color: #FFFFFF;
  height: 32px;
  padding: 3px 5px;
  margin: 5px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  font-size: 16px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease; }
  input[type=text]:focus, input[type=password]:focus, input[type=search]:focus, input[type=email]:focus, input[type=range]:focus, input[type=date]:focus, input[type=time]:focus, input[type=number]:focus, input[type=checkbox]:focus, input[type=radio]:focus {
    border-color: black; }
  input[type=text].valid, input[type=password].valid, input[type=search].valid, input[type=email].valid, input[type=range].valid, input[type=date].valid, input[type=time].valid, input[type=number].valid, input[type=checkbox].valid, input[type=radio].valid {
    box-shadow: 0 0 5px 0 rgba(0, 128, 0, 0.5); }
  input[type=text].invalid, input[type=password].invalid, input[type=search].invalid, input[type=email].invalid, input[type=range].invalid, input[type=date].invalid, input[type=time].invalid, input[type=number].invalid, input[type=checkbox].invalid, input[type=radio].invalid {
    box-shadow: 0 0 5px 0 rgba(255, 0, 0, 0.5); }

input[type=button], input[type=submit], button, .button {
  background-color: #FFFFFF;
  color: #000000;
  border: 1px solid #000000;
  margin: 5px;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5); }
  input[type=button]:active, input[type=submit]:active, button:active, .button:active {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
    box-shadow: 0 0 2px #000000; }
  input[type=button].negative, input[type=submit].negative, button.negative, .button.negative {
    background-color: #c80000; }
    input[type=button].negative:focus, input[type=submit].negative:focus, button.negative:focus, .button.negative:focus {
      background-color: #c83232; }
  input[type=button]::-moz-focus-inner, input[type=submit]::-moz-focus-inner, button::-moz-focus-inner, .button::-moz-focus-inner {
    border: 0; }
  input[type=button]:focus, input[type=submit]:focus, button:focus, .button:focus {
    background-color: rgba(201, 136, 218, 0.1);
    color: white; }
  input[type=button].disabled, input[type=submit].disabled, button.disabled, .button.disabled {
    color: gray;
    cursor: auto;
    cursor: initial; }

label {
  display: inline-block;
  text-align: left;
  margin-bottom: 15px; }
  label span {
    text-align: left;
    display: block;
    margin-bottom: 3px;
    overflow-x: hidden;
    white-space: nowrap; }
  label input {
    box-sizing: border-box;
    margin: 0px;
    border-radius: 5px;
    width: 100%; }
  label.name {
    display: block;
    width: 100%; }
  label.date, label.time, label.quantity {
    width: 30%;
    vertical-align: top; }
  label.calories, label.carb, label.fat, label.prot {
    width: 22%; }
  label.micros {
    width: 100%; }
    label.micros > div .micro {
      display: flex;
      justify-content: space-between;
      margin: 5px 0; }
      label.micros > div .micro .dropdown {
        display: inline-block;
        margin: 0;
        width: 70%; }
      label.micros > div .micro input[type=text] {
        width: 23%;
        margin: 0px; }

svg {
  width: 100%;
  max-width: 800px;
  height: 100%;
  font-family: 'Montserrat', sans-serif; }
  svg path {
    fill: none;
    stroke: #000000;
    stroke-width: 1px; }
  svg circle {
    fill: #000000; }
  svg text {
    font-family: 'Montserrat', sans-serif;
    text-fill: #000000; }
  svg .x-axis line, svg .y-axis line {
    stroke: rgba(0, 0, 0, 0.5);
    stroke-width: 1px; }
  svg .x-axis .tick line, svg .y-axis .tick line {
    fill: rgba(0, 0, 0, 0.5);
    stroke: rgba(0, 0, 0, 0.5); }
  svg .x-gridlines line, svg .y-gridlines line {
    stroke: rgba(0, 0, 0, 0.3);
    stroke-width: 1px; }
  svg text.x-axis, svg text.y-axis {
    font-weight: bold; }
  svg .curves path {
    stroke-width: 3px;
    stroke-linejoin: round; }
  svg .std path {
    fill: rgba(0, 128, 0, 0.3);
    stroke: none;
    stroke-linejoin: round; }

i.action {
  cursor: pointer;
  margin: 5px; }
  i.action:hover {
    color: rgba(0, 0, 0, 0.7); }

div.success-message {
  font-size: 10px;
  color: green; }

div.error-message {
  font-size: 10px;
  color: red; }

div.react-datepicker-wrapper {
  cursor: pointer; }

table {
  border-collapse: collapse; }
  table col.numbers {
    width: 100px; }
  table tr {
    border-bottom: solid 1px;
    border-top: solid 1px;
    border-color: rgba(0, 0, 0, 0.1); }
    table tr:hover {
      background-color: rgba(0, 0, 0, 0.1); }
    table tr.status td {
      text-align: center; }
  table td, table th {
    text-align: left;
    margin: 10px;
    padding: 5px 20px;
    border: 0; }
    table td[colspan='999'], table th[colspan='999'] {
      text-align: center; }

.dropdown-checkbox input {
  display: none; }

.col-sm-1 {
  grid-column-end: span 1; }

.col-1 {
  grid-column-end: span 1; }

.row-1 {
  grid-row-end: span 1; }

.col-sm-2 {
  grid-column-end: span 2; }

.col-2 {
  grid-column-end: span 2; }

.row-2 {
  grid-row-end: span 2; }

.col-sm-3 {
  grid-column-end: span 3; }

.col-3 {
  grid-column-end: span 3; }

.row-3 {
  grid-row-end: span 3; }

.col-sm-4 {
  grid-column-end: span 4; }

.col-4 {
  grid-column-end: span 4; }

.row-4 {
  grid-row-end: span 4; }

.col-sm-5 {
  grid-column-end: span 5; }

.col-5 {
  grid-column-end: span 5; }

.row-5 {
  grid-row-end: span 5; }

.col-sm-6 {
  grid-column-end: span 6; }

.col-6 {
  grid-column-end: span 6; }

.row-6 {
  grid-row-end: span 6; }

.col-sm-7 {
  grid-column-end: span 7; }

.col-7 {
  grid-column-end: span 7; }

.row-7 {
  grid-row-end: span 7; }

.col-sm-8 {
  grid-column-end: span 8; }

.col-8 {
  grid-column-end: span 8; }

.row-8 {
  grid-row-end: span 8; }

.col-sm-9 {
  grid-column-end: span 9; }

.col-9 {
  grid-column-end: span 9; }

.row-9 {
  grid-row-end: span 9; }

.col-sm-10 {
  grid-column-end: span 10; }

.col-10 {
  grid-column-end: span 10; }

.row-10 {
  grid-row-end: span 10; }

.col-sm-11 {
  grid-column-end: span 11; }

.col-11 {
  grid-column-end: span 11; }

.row-11 {
  grid-row-end: span 11; }

.col-sm-12 {
  grid-column-end: span 12; }

.col-12 {
  grid-column-end: span 12; }

.row-12 {
  grid-row-end: span 12; }

@media only screen and (min-width: 600px) and (orientation: portrait), (min-width: 769px) and (orientation: landscape) {
  main .main-card {
    margin: 100px 0; }
    main .main-card .card {
      width: unset;
      padding: 100px; }
  .col-lg-1 {
    grid-column-end: span 1; }
  .col-lg-2 {
    grid-column-end: span 2; }
  .col-lg-3 {
    grid-column-end: span 3; }
  .col-lg-4 {
    grid-column-end: span 4; }
  .col-lg-5 {
    grid-column-end: span 5; }
  .col-lg-6 {
    grid-column-end: span 6; }
  .col-lg-7 {
    grid-column-end: span 7; }
  .col-lg-8 {
    grid-column-end: span 8; }
  .col-lg-9 {
    grid-column-end: span 9; }
  .col-lg-10 {
    grid-column-end: span 10; }
  .col-lg-11 {
    grid-column-end: span 11; }
  .col-lg-12 {
    grid-column-end: span 12; } }

@media only screen and (min-width: 600px) and (max-width: 1024px) and (orientation: portrait) {
  .hide-tablet {
    display: none; } }

@media only screen and (max-width: 600px) and (orientation: portrait), (max-width: 768px) and (orientation: landscape) {
  .hide-mobile {
    display: none; } }

div.login-container, div.signup-container {
  text-align: center; }
  div.login-container form, div.signup-container form {
    display: inline-block;
    max-width: 500px; }

nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #000;
  text-align: left;
  white-space: nowrap;
  border-bottom: 1px solid;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.5);
  border: 0;
  color: #FFF;
  z-index: 1; }
  nav ul {
    list-style-type: none;
    width: 100%;
    padding: 0px;
    margin: 0;
    position: absolute;
    background-color: black;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.5);
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    overflow: hidden;
    -webkit-transform-origin: top;
            transform-origin: top; }
    nav ul li {
      -webkit-transition: visibility 0s ease 0.2s;
      transition: visibility 0s ease 0.2s;
      visibility: visible; }
    nav ul.closed {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      visibility: hidden; }
      nav ul.closed li {
        -webkit-transition: visibility 0s ease 0s;
        transition: visibility 0s ease 0s;
        visibility: hidden; }
  nav div.toggle-menu {
    display: inline-block;
    text-align: right;
    margin: 15px;
    vertical-align: top;
    cursor: pointer; }
    nav div.toggle-menu.user {
      float: right; }
  nav span.title {
    height: 0;
    line-height: 0;
    padding: 0;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    font-size: 20px; }
  nav li {
    margin: 1rem; }
  nav a {
    text-decoration: none;
    color: #FFF; }
    nav a:hover {
      text-decoration: underline; }

@media only screen and (min-width: 600px) and (orientation: portrait), (min-width: 769px) and (orientation: landscape) {
  .App {
    display: flex;
    flex-direction: row; }
  nav {
    display: inline-block;
    width: 300px;
    background: #000;
    text-align: left;
    white-space: nowrap;
    border-bottom: 1px solid;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.5);
    border: 0;
    color: #FFF; }
    nav span.title {
      position: static;
      display: block;
      width: 100%;
      text-align: center;
      padding: 3rem 0; }
    nav ul {
      display: block;
      position: static; }
      nav ul.closed {
        -webkit-transform: scaleY(1);
                transform: scaleY(1); }
        nav ul.closed li {
          visibility: visible; }
    nav div {
      display: inline-block;
      text-align: right;
      margin: 15px;
      vertical-align: top; }
      nav div.user {
        float: right; }
    nav li {
      display: block;
      margin: 1rem 2rem; }
    nav a {
      text-decoration: none;
      color: #FFF; }
      nav a:hover {
        text-decoration: underline; }
    nav div.toggle-menu {
      display: none; } }

.overview-page-container {
  text-align: center; }
  .overview-page-container .empty-view {
    display: inline-block;
    width: 100%;
    color: #c8c8c8;
    margin: auto; }
    .overview-page-container .empty-view .large-button {
      margin: 20px 5px; }
  .overview-page-container .progress-report {
    width: 100%;
    padding: 15px;
    box-sizing: border-box; }
  .overview-page-container span {
    font-weight: bold; }
  .overview-page-container .progress-bar {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px; }
    .overview-page-container .progress-bar text.right {
      -webkit-transform: translateX(1%);
              transform: translateX(1%); }
    .overview-page-container .progress-bar text.middle {
      -webkit-transform: translateX(50%);
              transform: translateX(50%); }
    .overview-page-container .progress-bar text.right {
      -webkit-transform: translateX(99%);
              transform: translateX(99%); }
  .overview-page-container svg {
    width: unset; }
    .overview-page-container svg rect.total {
      fill: rgba(255, 255, 255, 0.5);
      stroke: black;
      stroke-width: 1px; }
    .overview-page-container svg rect.progress {
      fill: rgba(63, 41, 66, 0.5);
      stroke: black;
      stroke-width: 1px; }
    .overview-page-container svg text {
      font-size: 16px; }
      .overview-page-container svg text.left, .overview-page-container svg text.right {
        display: none; }

@media only screen and (min-width: 600px) and (orientation: portrait), (min-width: 769px) and (orientation: landscape) {
  .home-page-container {
    text-align: center;
    display: inline-block; }
    .home-page-container .empty-view {
      display: inline-block;
      width: 500px;
      color: #323232;
      margin: auto; }
      .home-page-container .empty-view .large-button {
        display: inline-block;
        border: solid 1px black;
        border-radius: 5px;
        width: 150px;
        height: 150px;
        margin: 5px;
        cursor: pointer; }
        .home-page-container .empty-view .large-button:hover {
          background-color: #c8c8c8; }
        .home-page-container .empty-view .large-button i {
          display: block;
          width: 70px;
          height: 70px;
          margin: 20px auto;
          font-size: 70px; }
    .home-page-container .progress-report {
      max-width: 800px;
      margin: auto;
      padding: 15px;
      box-sizing: border-box; }
    .home-page-container input[type='text'] {
      width: 100px; }
    .home-page-container span {
      font-weight: bold; }
    .home-page-container .progress-bar {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px; }
    .home-page-container svg {
      width: unset; }
      .home-page-container svg rect.total {
        fill: rgba(255, 255, 255, 0.5);
        stroke: black;
        stroke-width: 1px; }
      .home-page-container svg rect.progress {
        fill: rgba(0, 128, 0, 0.7);
        stroke: black;
        stroke-width: 1px; }
      .home-page-container svg text {
        font-size: 16px;
        background-color: rgba(255, 255, 255, 0.5); } }

div.checkbox {
  display: inline-block; }
  div.checkbox label {
    display: inherit; }
    div.checkbox label input {
      display: none; }

div.autocomplete-input {
  position: relative; }
  div.autocomplete-input table {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5); }
    div.autocomplete-input table tbody {
      white-space: nowrap;
      text-overflow: clip; }
    div.autocomplete-input table td, div.autocomplete-input table th {
      width: 100%;
      text-align: left; }
      div.autocomplete-input table td.loading, div.autocomplete-input table th.loading {
        text-align: center; }
    div.autocomplete-input table tr {
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer; }
      div.autocomplete-input table tr.selected {
        background-color: #FFFFFF; }

.thumbnail {
  display: inline-block;
  margin: 6px;
  border-radius: 5px;
  border: 1px solid #000000;
  overflow: hidden;
  width: 128px;
  height: 128px;
  position: relative;
  text-align: center; }
  .thumbnail.selected {
    border-width: 3px;
    margin: 4px; }
  .thumbnail .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%; }
  .thumbnail img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    padding: 0;
    margin-left: -50%;
    margin-right: -50%; }
  .thumbnail i.material-icons {
    padding: 52px; }

.new-thumbnail {
  border-style: dotted; }
  .new-thumbnail label {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer; }
    .new-thumbnail label input[type='file'] {
      display: none; }

.thumbnail.group {
  padding: 0;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  vertical-align: top; }
  .thumbnail.group div {
    margin: 1px;
    width: 60px;
    height: 60px; }

.large-button {
  display: inline-block;
  border: solid 1px black;
  border-radius: 5px;
  width: 150px;
  height: 150px;
  margin: 5px;
  cursor: pointer;
  box-shadow: 0 4px 4px black;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white; }
  .large-button:hover {
    background-color: rgba(201, 136, 218, 0.1); }
  .large-button:active {
    box-shadow: 0 0 2px black;
    -webkit-transform: translateY(4px);
            transform: translateY(4px); }
  .large-button i {
    display: block;
    width: 70px;
    height: 70px;
    margin: 20px auto;
    font-size: 70px; }

.accordion {
  background-color: rgba(201, 136, 218, 0.1);
  margin: 10px 0; }
  .accordion > .heading {
    cursor: pointer;
    padding: 3px;
    text-align: left;
    font-size: 14px;
    font-weight: bold; }
    .accordion > .heading > i.material-icons {
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
      vertical-align: middle;
      margin: -3px 0; }
  .accordion > .content {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transform-origin: top;
            transform-origin: top;
    padding: 10px; }
  .accordion.collapsed > .content {
    display: none; }
  .accordion.expanded > .heading i.material-icons {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }

.dropdown {
  margin: 5px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  min-width: 100px;
  vertical-align: top; }
  .dropdown > .heading {
    box-sizing: border-box;
    background-color: #FFFFFF;
    color: #000000;
    cursor: pointer;
    height: 0;
    padding: 16px;
    padding-right: 0;
    margin: 0;
    font-size: 14px;
    line-height: 0;
    vertical-align: middle;
    text-align: left;
    border: 1px solid black;
    border-radius: 5px; }
    .dropdown > .heading > i.material-icons {
      -webkit-animation: ease 0.2s;
              animation: ease 0.2s;
      vertical-align: middle;
      margin: 0 5px 0 10px;
      line-height: 0;
      float: right; }
  .dropdown > .options {
    box-sizing: border-box;
    -webkit-animation: ease 0.2s;
            animation: ease 0.2s;
    border: 1px solid black;
    border-radius: 5px;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
    background-color: #FFFFFF;
    color: #000000;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    -webkit-transform-origin: top;
            transform-origin: top; }
    .dropdown > .options > .option {
      box-sizing: border-box;
      padding: 16px;
      margin: 0;
      line-height: 0;
      text-align: left;
      white-space: nowrap;
      overflow: hidden; }
      .dropdown > .options > .option:hover, .dropdown > .options > .option.hover {
        background-color: rgba(201, 136, 218, 0.1); }
  .dropdown.collapsed > .options {
    -webkit-transform: scaleY(0);
            transform: scaleY(0); }

div.modal-background {
  z-index: 10000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  /* Full width */
  height: 100vh;
  /* Full height */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  text-align: center;
  display: none; }
  div.modal-background.visible {
    display: block; }
  div.modal-background div.modal {
    background: #FFFFFF;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #000000;
    display: inline-block;
    text-align: left;
    border-radius: 5px; }
    div.modal-background div.modal div.close {
      float: right;
      cursor: pointer; }
    div.modal-background div.modal div.modal-header {
      display: block;
      width: 100%;
      font-size: 24pt;
      white-space: nowrap;
      margin-right: 50px;
      border-bottom: 1px solid #000000; }
    div.modal-background div.modal div.modal-body {
      margin: 10px; }
    div.modal-background div.modal div.modal-footer {
      text-align: right; }

div.breadcrumbs {
  text-align: left;
  padding: 10px; }
  div.breadcrumbs > a {
    margin: 0 5px; }
  div.breadcrumbs > .separator {
    display: inline-block; }
    div.breadcrumbs > .separator::after {
      content: '/'; }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
  50% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }
  100% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); } }

@keyframes bounce {
  0% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
  50% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }
  100% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); } }

div.loading {
  font-size: 32px; }
  div.loading.overlay {
    position: absolute;
    line-height: 0;
    top: 50%;
    left: 0;
    text-align: center;
    width: 100%; }
  div.loading span {
    display: inline-block;
    -webkit-animation-name: bounce;
            animation-name: bounce;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    margin: 5px;
    line-height: 0; }
  div.loading span:nth-child(1) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  div.loading span:nth-child(2) {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s; }
  div.loading span:nth-child(3) {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s; }

.photos-page-container .main-card .card {
  padding: 40px 0; }
  .photos-page-container .main-card .card h2 {
    margin-top: 0; }
  .photos-page-container .main-card .card input {
    display: none; }

@media only screen and (min-width: 600px) and (orientation: portrait), (min-width: 769px) and (orientation: landscape) {
  .photos-page-container .main-card .card {
    padding: 40px 100px; } }

.photo-page-container .photo img {
  max-width: 100%; }

.food-photos-gallery {
  position: relative;
  overflow-x: hidden;
  white-space: nowrap; }
  .food-photos-gallery .control {
    position: absolute;
    top: 0;
    height: 100%;
    width: 42px;
    color: white;
    opacity: 30%;
    cursor: pointer;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
    border-radius: 5px; }
    .food-photos-gallery .control > i {
      position: absolute;
      line-height: 0;
      top: 50%; }
    .food-photos-gallery .control.left {
      background: -webkit-gradient(linear, left top, right top, from(black), to(rgba(0, 0, 0, 0)));
      background: linear-gradient(to right, black 0%, rgba(0, 0, 0, 0) 100%);
      left: 0; }
      .food-photos-gallery .control.left i {
        left: 0; }
    .food-photos-gallery .control.right {
      background: -webkit-gradient(linear, right top, left top, from(black), to(rgba(0, 0, 0, 0)));
      background: linear-gradient(to left, black 0%, rgba(0, 0, 0, 0) 100%);
      right: 0; }
      .food-photos-gallery .control.right i {
        right: 0; }
    .food-photos-gallery .control:hover {
      opacity: 80%; }
  .food-photos-gallery .scrollable-container {
    -webkit-transition: -webkit-transform 0.2s ease;
    transition: -webkit-transform 0.2s ease;
    transition: transform 0.2s ease;
    transition: transform 0.2s ease, -webkit-transform 0.2s ease;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
    .food-photos-gallery .scrollable-container > * {
      vertical-align: middle; }
    .food-photos-gallery .scrollable-container .thumbnail {
      cursor: pointer; }
  .food-photos-gallery label {
    margin: 0; }
    .food-photos-gallery label input[type='file'] {
      display: none; }
  .food-photos-gallery .thumbnail .overlay i {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 32px;
    padding: 0;
    background-color: black;
    border-radius: 0 0 0 5px;
    padding: 2px; }

.diet-page-container {
  text-align: center;
  box-sizing: border-box; }
  .diet-page-container .main-card {
    box-sizing: border-box;
    margin: 30px 0px; }
    .diet-page-container .main-card .card {
      display: inline-block;
      padding: 100px 0;
      width: 100%; }
    .diet-page-container .main-card span {
      color: #969696;
      font-size: 12px;
      display: block; }
    .diet-page-container .main-card input {
      border-color: black;
      background-color: white;
      width: 100%;
      max-width: 230px; }
  .diet-page-container .card.header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5px;
    text-align: left; }
    .diet-page-container .card.header .breadcrumbs {
      display: inline;
      color: grey; }
      .diet-page-container .card.header .breadcrumbs a {
        color: #FFFFFF; }
  .diet-page-container .food-table-container {
    margin: 25px 3px; }
  .diet-page-container .controls input[type='file'] {
    display: none; }
  @media only screen and (min-width: 600px) and (orientation: portrait), (min-width: 769px) and (orientation: landscape) {
    .diet-page-container .main-card {
      margin: 100px 0; }
      .diet-page-container .main-card .card {
        padding: 100px;
        width: unset; } }

h3.date {
  display: inline-block;
  margin: 0;
  white-space: nowrap; }
  h3.date i {
    vertical-align: middle; }
  h3.date span {
    vertical-align: middle;
    margin: 0 30px; }

.new-entry-form {
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .new-entry-form .add-micro {
    font-size: 10px;
    text-align: left;
    cursor: pointer; }
  .new-entry-form div {
    width: 100%; }
  .new-entry-form input {
    background-color: rgba(255, 255, 255, 0.1);
    color: white; }

table.food-table {
  display: inline-block; }
  table.food-table tr {
    height: 42px; }
    table.food-table tr td, table.food-table tr th {
      padding: 21px 10px;
      line-height: 0; }
    table.food-table tr th {
      color: grey; }
    table.food-table tr .empty {
      color: rgba(0, 0, 0, 0.5); }
  table.food-table i {
    float: right;
    line-height: 0;
    cursor: pointer;
    margin-left: 10px; }

.gallery.small .thumbnails {
  white-space: nowrap;
  overflow-x: scroll; }

.gallery input[type=file] {
  display: none; }

.search-table-container {
  box-sizing: border-box;
  margin: 20px 0;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  width: 100%; }
  .search-table-container .search {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    color: grey;
    padding: 10px;
    margin-bottom: 1rem;
    width: 50%;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease; }
    .search-table-container .search i.material-icons {
      position: absolute;
      line-height: 0;
      top: 50%;
      left: 20px; }
    .search-table-container .search input {
      border: 1px solid grey;
      border-radius: 10px;
      padding: 10px 20px 10px 50px;
      color: grey; }
    .search-table-container .search:focus-within {
      color: black;
      width: 100%; }
      .search-table-container .search:focus-within input {
        color: black;
        border-color: black; }
  .search-table-container .search-table {
    display: inline-block;
    max-width: 100%;
    overflow-x: scroll; }
  .search-table-container tr {
    box-sizing: border-box;
    padding: 1px; }
    .search-table-container tr.selected {
      border: 2px solid black;
      padding: 0; }
      .search-table-container tr.selected td {
        padding-top: 4px; }
      .search-table-container tr.selected td:first-of-type {
        padding-left: 19px; }
      .search-table-container tr.selected td:last-of-type {
        padding-right: 19px; }
  .search-table-container td {
    white-space: nowrap;
    position: relative; }
    .search-table-container td[colspan='999'] {
      background-color: inherit;
      font-weight: bold;
      border: 1px solid grey; }
  .search-table-container .overlay-controls {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 0;
    z-index: 1; }
    .search-table-container .overlay-controls button {
      margin: 0;
      margin-left: 5px;
      font-size: 10px; }

.suggestions-container .suggestion {
  border: 1px solid black;
  border-radius: 5px;
  text-align: left;
  margin: 5px 0; }
  .suggestions-container .suggestion i {
    line-height: 0;
    padding: 21px 10px;
    cursor: pointer; }
  .suggestions-container .suggestion span {
    vertical-align: top;
    font-size: 14px;
    padding: 12px 0;
    display: inline-block; }

.nutrition-search-container .nutrition {
  border: 1px solid black;
  border-radius: 5px;
  text-align: left;
  padding: 5px;
  margin: 5px 0;
  cursor: pointer; }
  .nutrition-search-container .nutrition:focus {
    background-color: rgba(201, 136, 218, 0.1); }
  .nutrition-search-container .nutrition span {
    font-size: 10px;
    margin: 0 10px; }
    .nutrition-search-container .nutrition span.name {
      font-size: 14px;
      display: block; }

.new-entry-field-container {
  margin: 10px;
  position: relative;
  box-sizing: border-box;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transform-origin: top;
          transform-origin: top; }
  .new-entry-field-container.hidden {
    display: none; }
  .new-entry-field-container input {
    width: 100%;
    margin: 0; }
  .new-entry-field-container div.suggestions {
    box-sizing: border-box;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: white;
    width: 100%;
    border: 1px solid black;
    border-radius: 5px; }
    .new-entry-field-container div.suggestions > div.suggestion {
      box-sizing: border-box;
      padding: 16px 20px;
      height: 32px;
      line-height: 0;
      text-align: left; }
      .new-entry-field-container div.suggestions > div.suggestion.selected {
        background-color: rgba(201, 136, 218, 0.1); }
      .new-entry-field-container div.suggestions > div.suggestion ul.details {
        display: inline-block;
        margin: 0;
        font-size: 10px;
        color: grey; }
        .new-entry-field-container div.suggestions > div.suggestion ul.details li {
          display: inline-block;
          margin-left: 2em; }
          .new-entry-field-container div.suggestions > div.suggestion ul.details li .label {
            font-weight: bold;
            margin-right: 1em; }

.body-page-container {
  text-align: center;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 15px;
  padding: 15px; }
  .body-page-container .main-card {
    box-sizing: border-box;
    margin: 30px 0px; }
    .body-page-container .main-card .card {
      display: inline-block;
      padding: 100px 0;
      width: 100%; }
    .body-page-container .main-card span {
      color: #969696;
      font-size: 12px;
      display: block; }
    .body-page-container .main-card input {
      border-color: black;
      background-color: white;
      width: 100%;
      max-width: 230px; }
  .body-page-container .stats-card h3 {
    text-align: left;
    margin-top: 0; }
  .body-page-container .stats-card span {
    font-size: 32px; }
  .body-page-container .bodyweight-table-container {
    display: inline-block;
    max-width: 500px;
    position: relative; }
    .body-page-container .bodyweight-table-container table {
      width: 100%; }
      .body-page-container .bodyweight-table-container table th {
        color: grey; }
      .body-page-container .bodyweight-table-container table tr {
        cursor: pointer; }
        .body-page-container .bodyweight-table-container table tr.selected {
          background-color: rgba(255, 255, 255, 0.3); }
        .body-page-container .bodyweight-table-container table tr td {
          padding: 10px 20px;
          color: white;
          -webkit-transition: 0.5s ease;
          transition: 0.5s ease; }
  .body-page-container .bodyweight-plot-container {
    padding: 20px;
    position: relative; }
    .body-page-container .bodyweight-plot-container svg text {
      fill: white; }
    .body-page-container .bodyweight-plot-container svg path {
      stroke: #C988DA; }
    .body-page-container .bodyweight-plot-container svg .std path {
      stroke: none;
      fill: rgba(201, 136, 218, 0.2); }
    .body-page-container .bodyweight-plot-container svg .x-axis path, .body-page-container .bodyweight-plot-container svg .y-axis path {
      stroke: white; }
  @media only screen and (min-width: 600px) and (orientation: portrait), (min-width: 769px) and (orientation: landscape) {
    .body-page-container .main-card {
      margin: 100px 0; }
      .body-page-container .main-card .card {
        padding: 100px;
        width: unset; } }

main.user-page-container h3 {
  text-align: left;
  margin: 20px; }

main.user-page-container form {
  margin: 20px;
  text-align: left; }
  main.user-page-container form div {
    margin-left: 20px; }
  main.user-page-container form label {
    white-space: nowrap;
    display: block;
    margin-left: 20px;
    vertical-align: middle; }
    main.user-page-container form label span {
      display: inline-block;
      width: 50%;
      max-width: 200px;
      margin-right: 20px; }
    main.user-page-container form label input {
      display: inline-block;
      max-width: 40%; }
    main.user-page-container form label .dropdown {
      width: 40%;
      margin: 5px; }
  main.user-page-container form button {
    margin: 20px; }

.data-page-container {
  vertical-align: top; }

.tag-page-container > div {
  vertical-align: top;
  display: inline-block;
  width: 50%; }

.photo-viewer-thumbnail {
  display: inline-block; }

.food-photo-container {
  display: inline-block; }

.labeller-controls-container {
  display: inline-block;
  vertical-align: top;
  padding: 10px 50px; }
  .labeller-controls-container div.buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; }

.labelled-food-photo {
  text-align: left;
  max-width: 700px;
  position: relative;
  display: inline-block; }
  .labelled-food-photo img {
    display: inline-block; }
  .labelled-food-photo .food-photo {
    position: absolute;
    left: 0;
    top: 0;
    width: inherit;
    height: inherit; }
    .labelled-food-photo .food-photo rect, .labelled-food-photo .food-photo polygon {
      fill: none;
      stroke-width: 1px;
      stroke: black; }
    .labelled-food-photo .food-photo .control {
      cursor: move; }

.tag {
  display: inline-block;
  white-space: nowrap;
  border: 1px solid #000000;
  margin: 10px;
  padding: 0;
  font-size: 10px;
  border-radius: 3px; }
  .tag .label {
    display: inline-block;
    border-right: solid 1px #000000;
    margin: 3px;
    margin-right: 0;
    padding-right: 5px;
    cursor: pointer; }
  .tag .close {
    display: inline-block;
    padding: 3px;
    cursor: pointer; }
    .tag .close:hover {
      background-color: rgba(201, 136, 218, 0.1); }

.new-tag-form label {
  display: block;
  white-space: nowrap; }

.new-tag-form input {
  display: inline-block; }

.tag-selector {
  display: inline-block; }
  .tag-selector input {
    width: 15rem;
    font-size: 10px; }

