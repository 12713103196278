@import 'variables';

.diet-page-container {
  text-align: center;
  box-sizing: border-box;

  .main-card {
    box-sizing: border-box;
    margin: 30px 0px;
    .card {
      display: inline-block;
      padding: 100px 0;
      width: 100%;
    }
    span {
      color: rgb(150,150,150);
      font-size: 12px;
      display: block;
    }
    input {
      border-color: black;
      background-color: white;
      width: 100%;
      max-width: 230px;
    }
  }
  .card {
    &.header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 5px;
      text-align: left;

      .breadcrumbs {
        display: inline;
        color: grey;
        a {
          color: $secondary-colour;
        }
      }
    }
  }

  .food-table-container {
    margin: 25px 3px;
  }

  .gallery {
    //overflow-x: scroll;
    //white-space: nowrap;
  }
  .controls {
    input[type='file'] {
      display: none;
    }
  }

  @media #{$computer-and-tablet} {
    .main-card {
      margin: 100px 0;
      .card {
        padding: 100px;
        width: unset;
      }
    }
  }
}

h3.date {
  display: inline-block;
  margin: 0;
  white-space: nowrap;
  i {
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
    margin: 0 30px;
  }
}

.new-entry-form {
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .add-micro {
    font-size: 10px;
    text-align: left;
    cursor: pointer;
  }
  div {
    width: 100%;
  }
  input {
    background-color: rgba(white,0.1);
    color: white;
  }
}

table.food-table {
  display: inline-block;
  tr {
    height: 42px;
    td, th {
      padding: 21px 10px;
      line-height: 0;
    }
    th {
      color: grey;
    }
    .empty {
      color: $faded-primary-colour;
    }
  }
  i {
    float: right;
    line-height: 0;
    cursor: pointer;
    margin-left: 10px;
  }
}

.gallery {
  &.small {
    .thumbnails {
      white-space: nowrap;
      overflow-x: scroll;
    }
  }
  input[type=file] {
    display: none;
  }
}

.search-table-container {
	box-sizing: border-box;
	margin: 20px 0;
	padding: 10px;
	border-radius: 10px;
	//border: 1px solid grey;
	text-align: center;
	width: 100%;
	.search {
		position: relative;
		box-sizing: border-box;
		display: inline-block;
		color: grey;
		padding: 10px;
		margin-bottom: 1rem;
		width: 50%;
		transition: 0.3s ease;
		i.material-icons {
			position: absolute;
			line-height: 0;
			top: 50%;
			left: 20px;
		}
		input {
			border: 1px solid grey;
			border-radius: 10px;
			padding: 10px 20px 10px 50px;
			color: grey;
		}
		&:focus-within {
			color: black;
			width: 100%;
			input {
				color: black;
				border-color: black;
			}
		}
	}
	.search-table {
		display: inline-block;
		max-width: 100%;
		overflow-x: scroll;
	}
	tr {
		box-sizing: border-box;
		padding: 1px;
		&.selected {
			border: 2px solid black;
			padding: 0;
			td {
				padding-top: 4px;
			}
			td:first-of-type {
				padding-left: 19px;
			}
			td:last-of-type {
				padding-right: 19px;
			}
		}
	}
	td {
		white-space: nowrap;
		position: relative;
		&[colspan='999'] {
			background-color: inherit;
			font-weight: bold;
			border: 1px solid grey;
		}
	}
	.overlay-controls {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		z-index: 1;
		button {
			margin: 0;
			margin-left: 5px;
			font-size: 10px;
		}
	}
}

.suggestions-container {
  .suggestion {
    border: 1px solid black;
    border-radius: 5px;
    text-align: left;
    margin: 5px 0;
    //vertical-align: top;
    i {
      line-height: 0;
      padding: 21px 10px;
      cursor: pointer;
    }
    span {
      vertical-align: top;
      font-size: 14px;
      padding: 12px 0;
      display: inline-block;
    }
  }
}

.nutrition-search-container {
  .nutrition {
    border: 1px solid black;
    border-radius: 5px;
    text-align: left;
    padding: 5px;
    margin: 5px 0;
    cursor: pointer;
    &:focus {
      background-color: $focus-colour;
    }
    span {
      font-size: 10px;
      margin: 0 10px;
      &.name {
        font-size: 14px;
        display: block;
      }
    }
  }
}

.new-entry-field-container {
  margin: 10px;
  position: relative;
  box-sizing: border-box;
  transition: 0.3s ease;
  transform-origin: top;
  &.hidden {
    display: none;
  }
  input {
    width: 100%;
    margin: 0;
  }
  div.suggestions {
    box-sizing: border-box;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: white;
    width: 100%;
    border: 1px solid black;
    border-radius: 5px;
    > div.suggestion {
      box-sizing: border-box;
      padding: 16px 20px;
      height: 32px;
      line-height: 0;
      text-align: left;
      &.selected {
        background-color: $focus-colour;
      }
      ul.details {
        display: inline-block;
        margin: 0;
        font-size: 10px;
        color: grey;
        li {
          display: inline-block;
          margin-left: 2em;
          .label {
            font-weight: bold;
            margin-right: 1em;
          }
          .value {
          }
        }
      }
    }
  }
}
