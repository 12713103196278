@import 'variables';

div.login-container, div.signup-container {
  text-align: center;
  form {
    display: inline-block;
    max-width: 500px;
  }
}

nav {
  position: sticky;
  top: 0;
  background: #000;
  text-align: left;
  white-space: nowrap;
  border-bottom: 1px solid;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, .5);
  border: 0;
  color: #FFF;
  //position: relative;
  z-index: 1;
  ul {
    list-style-type: none;
    width: 100%;
    padding: 0px;
    margin: 0;
    position: absolute;
    background-color: black;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, .5);
    transition: all .2s ease;
    overflow: hidden;
    transform-origin: top;
    li {
      transition: visibility 0s ease 0.2s;
      visibility: visible;
    }
    &.closed {
      transform: scaleY(0);
      visibility: hidden;
      li {
        transition: visibility 0s ease 0s;
        visibility: hidden;
      }
    }
  }
  div.toggle-menu {
    display: inline-block;
    text-align: right;
    margin: 15px;
    vertical-align: top;
    cursor: pointer;
    &.user {
      float: right;
    }
  }
  span.title {
    height: 0;
    line-height: 0;
    padding: 0;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    font-size: 20px;
  }
  li {
    margin: 1rem;
  }
  a {
    text-decoration: none;
    color: #FFF;
    &:hover {
      text-decoration: underline;
    }
  }
}

@media #{$computer-and-tablet} {
  .App {
    display: flex;
    flex-direction: row;
  }
  nav {
    display: inline-block;
    width: 300px;
    background: #000;
    text-align: left;
    white-space: nowrap;
    border-bottom: 1px solid;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, .5);
    border: 0;
    color: #FFF;
    span.title {
      position: static;
      display: block;
      width: 100%;
      text-align: center;
      padding: 3rem 0;
    }
    ul {
      display: block;
      position: static;
      &.closed {
        transform: scaleY(1);
        li {
          visibility: visible;
        }
      }
    }
    div {
      display: inline-block;
      text-align: right;
      margin: 15px;
      vertical-align: top;
      &.user {
        float: right;
      }
    }
    li {
      display: block;
      margin: 1rem 2rem;
    }
    a {
      text-decoration: none;
      color: #FFF;
      &:hover {
        text-decoration: underline;
      }
    }
    div.toggle-menu {
      display: none;
    }
  }
}

