$background-colour: #FFFFFF;
$primary-colour: #000000;
$secondary-colour: #FFFFFF;
$faded-primary-colour: rgba($primary-colour, 0.5);

$light-colour-1: #D88181;
$light-colour-2: #C988DA;
$dark-colour-2: #3F2942;
$background-gradient: linear-gradient(to bottom right, $light-colour-1, $light-colour-2);

$focus-colour: rgba($light-colour-2, 0.1);
$success-colour: green;
$error-colour: red;

$shadow-colour: rgba($primary-colour, 0.5);
$shadow: 2px 4px 4px $shadow-colour;

// z-indices
$modal-z: 10000;
$sticky-z: 1;

// Media queries
$mobile: "only screen and 
    (max-width: 600px) and (orientation: portrait),
    (max-width: 768px) and (orientation: landscape)";
$tablet: "only screen and 
    (min-width: 600px) and (max-width: 1024px) and (orientation: portrait)";
$computer: "only screen and (min-width: 1024px)";
$computer-and-tablet: "only screen and 
    (min-width: 600px) and (orientation: portrait),
    (min-width: 769px) and (orientation: landscape)";
