@import 'variables';

div.checkbox {
  display: inline-block;
  label {
    display: inherit;
    input {
      display: none;
    }
  }
}

div.autocomplete-input {
  position: relative;

  table {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    background-color: rgba(255,255,255,.8);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.5);

    tbody {
      white-space: nowrap;
      text-overflow: clip;
    }
    td, th {
      width: 100%;
      text-align: left;

      &.loading {
        text-align: center;
      }
    }
    tr {
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;

      &.selected {
        background-color: $background-colour;
      }
    }
  }
}

.thumbnail {
  display: inline-block;
  margin: 6px;
  border-radius: 5px;
  border: 1px solid $primary-colour;
  overflow: hidden;
  width: 128px;
  height: 128px;
  position: relative;
  text-align: center;

  &.selected {
    border-width: 3px;
    margin: 4px;
  }

  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    padding: 0;
    margin-left: -50%;
    margin-right: -50%;
  }

  i.material-icons {
    padding: 52px;
  }
}

.new-thumbnail {
  border-style: dotted;
  label {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    input[type='file'] {
      display: none;
    }
  }
}

.thumbnail.group {
  padding: 0;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  vertical-align: top;
  div {
    margin: 1px;
    width: 60px;
    height: 60px;
  }
}

.large-button {
  display: inline-block;
  border: solid 1px black;
  border-radius: 5px;
  width: 150px;
  height: 150px;
  margin: 5px;
  cursor: pointer;
  box-shadow: 0 4px 4px black;
  text-align: center;
  background-color: $faded-primary-colour;
  color: white;
  &:hover {
    background-color: $focus-colour;
  }
  &:active {
    box-shadow: 0 0 2px black;
    transform: translateY(4px);
  }
  i {
    display: block;
    width: 70px;
    height: 70px;
    margin: 20px auto;
    font-size: 70px;
  }
}

.accordion {
  background-color: $focus-colour;
  margin: 10px 0;
  // Default styles
  > .heading {
    cursor: pointer;
    padding: 3px;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    > i.material-icons {
      transition: all .3s ease;
      vertical-align: middle;
      margin: -3px 0;
    }
  }
  > .content {
    transition: all .3s ease;
    transform-origin: top;
    padding: 10px;
  }
  // Collapsed
  &.collapsed > .heading {
  }
  &.collapsed > .content {
    display: none;
  }
  // Expanded
  &.expanded > .heading {
    i.material-icons {
      transform: rotate(90deg);
    }
  }
  &.expanded > .content {
  }
}

.dropdown {
  margin: 5px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  min-width: 100px;
  vertical-align: top;
  // Default styles
  > .heading {
    box-sizing: border-box;
    background-color: $background-colour;
    color: $primary-colour;
    cursor: pointer;
    height: 0;
    padding: 16px;
    padding-right: 0;
    margin: 0;
    font-size: 14px;
    line-height: 0;
    vertical-align: middle;
    text-align: left;
    border: 1px solid black;
    border-radius: 5px;
    > i.material-icons {
      animation: ease 0.2s;
      vertical-align: middle;
      margin: 0 5px 0 10px;
      //position: absolute;
      //right: 0;
      line-height: 0;
      float: right;
    }
  }
  > .options {
    box-sizing: border-box;
    animation: ease 0.2s;
    border: 1px solid black;
    border-radius: 5px;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    box-shadow: $shadow;
    background-color: $background-colour;
    color: $primary-colour;
    transition: all .2s ease;
    transform-origin: top;
    > .option {
      box-sizing: border-box;
      padding: 16px;
      margin: 0;
      line-height: 0;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      &:hover, &.hover {
        background-color: $focus-colour;
      }
    }
  }
  // Collapsed
  &.collapsed > .heading {
  }
  &.collapsed > .options {
    transform: scaleY(0);
  }
  // Expanded
  &.expanded > .heading {
  }
  &.expanded > .options {
  }
}

div.modal-background {
  z-index: 10000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  text-align: center;
  display: none;

  &.visible {
    display: block;
  }

  div.modal {
    background: $background-colour;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid $primary-colour;
    display: inline-block;
    text-align: left;
    border-radius: 5px;

    div.close {
      float: right;
      cursor: pointer;
    }

    div.modal-header {
      display: block;
      width: 100%;
      font-size: 24pt;
      white-space: nowrap;
      margin-right: 50px;
      border-bottom: 1px solid $primary-colour;
    }

    div.modal-body {
      margin: 10px;
    }

    div.modal-footer {
      text-align: right;
    }
  }
}

div.breadcrumbs {
  text-align: left;
  padding: 10px;
  > a {
    margin: 0 5px;
  }
  > .separator {
    display: inline-block;
    &::after {
      content: '/';
    }
  }
}

@keyframes bounce {
  0% { transform: translateY(-5px); }
  50% { transform: translateY(5px); }
  100% { transform: translateY(-5px); }
}

div.loading {
  font-size: 32px;
  &.overlay {
    position: absolute;
    line-height: 0;
    top: 50%;
    left: 0;
    text-align: center;
    width: 100%;
  }
  span {
    display: inline-block;
    animation-name: bounce;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin: 5px;
    line-height: 0;
  }
  span:nth-child(1) {
    animation-delay: 0.2s;
  }
  span:nth-child(2) {
    animation-delay: 0.4s;
  }
  span:nth-child(3) {
    animation-delay: 0.6s;
  }
}
