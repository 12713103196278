@import 'variables';

.data-page-container {
  vertical-align: top;
}

.tag-page-container {
  > div {
    vertical-align: top;
    display: inline-block;
    width: 50%;
  }
}

.photo-viewer-thumbnail{
  display: inline-block;
}

.food-photo-container {
  display: inline-block;
}

.labeller-controls-container {
  display: inline-block;
  vertical-align: top;
  padding: 10px 50px;

  div.buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.labelled-food-photo {
  text-align: left;
  max-width: 700px;
  position: relative;
  display: inline-block;
  img {
    display: inline-block;
  }
  .food-photo {
    position: absolute;
    left: 0;
    top: 0;
    width: inherit;
    height: inherit;

    rect, polygon {
      fill: none;
      stroke-width: 1px;
      stroke: black;
    }

    .control {
      cursor: move;
    }
  }
}

.tag {
  display: inline-block;
  white-space: nowrap;
  border: 1px solid $primary-colour;
  margin: 10px;
  padding: 0;
  font-size: 10px;
  border-radius: 3px;
  .label {
    display: inline-block;
    border-right: solid 1px $primary-colour;
    margin: 3px;
    margin-right: 0;
    padding-right: 5px;
    cursor: pointer;
  }
  .close {
    display: inline-block;
    padding: 3px;
    cursor: pointer;
    &:hover {
      background-color: $focus-colour;
    }
  }
}

.new-tag-form {
  label {
    display: block;
    white-space: nowrap;
  }
  input {
    display: inline-block;
  }
}

.tag-selector {
  display: inline-block;
  input {
    width: 15rem;
    font-size: 10px;
  }
}
