@import '_variables';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: $primary-colour;
  text-align: center;
  position:relative;
  min-height:100vh;
  background-image: $background-gradient;
}

main {
  min-height: 100vh;
  width: 100%;
  max-width: 1000px;
  text-align: center;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 15px;
  align-content: start;
  padding: 15px;

  .card {
    background-color: $dark-colour-2;
    color: white;
    border-radius: 5px;
    box-shadow: 0 5px 5px 0 rgba(black,.4);
    overflow: hidden;
    padding: 15px;
  }
  .main-card {
    box-sizing: border-box;
    margin: 30px 0px;
    .card {
      display: inline-block;
      padding: 100px 0;
      width: 100%;
    }
    span {
      color: rgb(150,150,150);
      font-size: 12px;
      display: block;
    }
  }
}

div.notification-container {
  position: fixed;
  top: 0;
  padding: 20px;
  z-index: 10;
  .notification {
    text-align: left;
    background-color: white;
    border: 1px solid black;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: 10px;
  }
}

a {
  color: $primary-colour;
  outline: none;
  text-decoration: none;
}

input {
  &[type=text], &[type=password], &[type=search], &[type=email], &[type=range], &[type=date], &[type=time], &[type=number], &[type=checkbox], &[type=radio] {
    //width: 100%;
    vertical-align: middle;
    color: $primary-colour;
    background-color: $background-colour;
    height: 32px;
    padding: 3px 5px;
    margin: 5px;
    box-sizing: border-box;
    border: 1px solid rgba($primary-colour,0.4);
    border-radius: 5px;
    font-size: 16px;

    transition: 0.3s ease;
    &:focus {
      border-color: rgba($primary-colour,1);
    }

    &.valid {
      box-shadow: 0 0 5px 0 rgba($success-colour,.5);
    }

    &.invalid {
      box-shadow: 0 0 5px 0 rgba($error-colour,.5);
    }
  }
}

input[type=button], input[type=submit], button, .button {
  background-color: $background-colour;
  color: $primary-colour;
  border: 1px solid $primary-colour;
  margin: 5px;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  box-shadow: $shadow;

  &:active {
    transform: translateY(4px);
    box-shadow: 0 0 2px $primary-colour;
  }

  &.negative {
    background-color: rgb(200,0,0);
    &:focus {
      background-color: rgb(200,50,50);
    }
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &:focus {
    background-color: $focus-colour;
    color: white;
  }

  &.disabled {
    color: rgb(128,128,128);
    cursor: initial;
  }
}

  label {
    display: inline-block;
    text-align: left;
    margin-bottom: 15px;
    span {
      text-align: left;
      display: block;
      margin-bottom: 3px;
      overflow-x: hidden;
      white-space: nowrap;
    }
    input {
      box-sizing: border-box;
      //display: block;
      //height: 32px;
      //border: 1px black solid;
      margin: 0px;
      border-radius: 5px;
      width: 100%;
    }
    &.name {
      display: block;
      width: 100%;
    }
    &.date, &.time, &.quantity {
      width: 30%;
      vertical-align: top;
    }
    &.calories, &.carb, &.fat, &.prot {
      width: 22%;
    }
    &.micros {
      width: 100%;
      > div {
        .micro {
          display: flex;
          justify-content: space-between;
          margin: 5px 0;
          .dropdown {
            display: inline-block;
            margin: 0;
            width: 70%;
          }
          input[type=text] {
            width: 23%;
            margin: 0px;
          }
        }
      }
    }
  }

svg {
  width: 100%;
  max-width: 800px;
  height: 100%;
  font-family: 'Montserrat', sans-serif;

  path {
    fill: none;
    stroke: $primary-colour;
    stroke-width: 1px;
  }

  circle {
    fill: $primary-colour;
  }

  text {
    font-family: 'Montserrat', sans-serif;
    text-fill: $primary-colour;
  }

  .x-axis, .y-axis {
    line {
      stroke: rgba($primary-colour, 0.5);
      stroke-width: 1px;
    }
    .tick line {
      fill: rgba(0,0,0,0.5);
      stroke: rgba(0,0,0,0.5);
    }
  }
  .x-gridlines, .y-gridlines {
    line {
      stroke: rgba($primary-colour, 0.3);
      stroke-width: 1px;
    }
  }
  text.x-axis, text.y-axis {
    font-weight: bold;
  }

  .curves {
    path {
      stroke-width: 3px;
      stroke-linejoin: round;
    }
  }
  .std {
    path {
      fill: rgba(green,0.3);
      stroke: none;
      stroke-linejoin: round;
    }
  }
}

i.action {
  cursor: pointer;
  margin: 5px;
  &:hover {
    color: rgba($primary-colour, 0.7);
  }
}

div.success-message {
  font-size: 10px;
  color: $success-colour;
}

div.error-message {
  font-size: 10px;
  color: $error-colour;
}

div.react-datepicker-wrapper {
  cursor: pointer;
}

table {
  border-collapse: collapse;
  col {
    &.numbers {
      width: 100px;
    }
  }
  tr {
    border-bottom: solid 1px;
    border-top: solid 1px;
    border-color: rgba($primary-colour, 0.1);
    &:hover {
      background-color: rgba(0,0,0,0.1);
    }
    &.status td {
      text-align: center;
    }
  }
  td, th {
    text-align: left;
    margin: 10px;
    padding: 5px 20px;
    border: 0;
    &[colspan='999'] {
      text-align: center;
      //background-color: rgba(0,0,0,0.4);
    }
  }
}

.dropdown-checkbox input{
  display: none;
}

@for $i from 1 through 12{
  .col-sm-#{$i}{
    grid-column-end: span $i;
  }
  .col-#{$i}{
    grid-column-end: span $i;
  }
  .row-#{$i}{
    grid-row-end: span $i;
  }
}

@media #{$computer-and-tablet} {
  main {
    .main-card {
      margin: 100px 0;
      .card {
        width: unset;
        padding: 100px;
      }
    }
  }

  @for $i from 1 through 12{
    .col-lg-#{$i}{
      grid-column-end: span $i;
    }
  }
}

@media #{$computer} {
}

@media #{$tablet} {
  .hide-tablet {
    display: none;
  }
}

@media #{$mobile} {
  .hide-mobile{
    display: none;
  }
}
